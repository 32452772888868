import { RootState } from '@root/store'
import { createSelector } from 'reselect'
import { ContentEntity, ModuleContentInterface } from '@apis/contents-microservice/_entities/_types'
import { BundleState } from '@store/bundle-slice/_types'
import {
  ModuleContentItemsInterface,
  ModuleProgressInterface,
} from '@shared/interfaces/content/module/module.interface'
import { compareArrayOfObjects, isEmptyArray } from '@utils/arrays'
import formatDigitalDuration from '@utils/format-digital-duration'
import buildPathWithParams from '@utils/build-path-with-params'
import { DEFAULT_AUTOPLAY_QUERY_OBJECT, ROUNDER } from '@shared/constants/root.constants'
import { selectContentResumePlaybackById } from '@store/contents-slice/_selectors'
import { getPercentage } from '@utils/connascence'
import { ContentsState } from '@store/contents-slice/_types'
import { hasProp } from '@utils/has-prop'

/**
 * Select bundle by content id
 * @param contentId
 * @returns ContentEntity
 */
export const selectContentBundleByContentId = createSelector(
  [({ bundles }: RootState) => bundles, (state, contentId: string) => contentId],
  (bundles, contentId): ContentEntity | undefined => {
    return bundles?.byContentId?.[contentId]
  },
)

/**
 * Select bundle by bundle id
 * @param bundleId
 * @returns ContentEntity
 */
export const selectContentBundleByBundleId = createSelector(
  [({ bundles }: RootState) => bundles, (state, bundleId: string) => bundleId],
  (bundles, bundleId): ContentEntity | undefined => {
    return bundles?.byBundleId[bundleId]
  },
)

/**
 * Select bundle content fetch status
 * @param bundles
 */
export const selectContentBundleFetchStatus = ({ bundles }: RootState): string => {
  return bundles?.contentFetchStatus
}

/**
 * Select bundle content title by content id
 * @param contentId
 */
export const selectBundleContentTitle = createSelector(
  [({ bundles }: RootState) => bundles, (state, contentId: string) => contentId],
  (bundles: BundleState, contentId): string => {
    return bundles?.byContentId?.[contentId]?.title ?? ''
  },
)

/**
 * Select Content and Module of a bundle content by content id
 * @param bundleContentId
 * @param contentId
 */
export const selectContentAndModuleOnBundleContentByContentId = createSelector(
  [({ bundles }: RootState) => bundles, (state, payload) => payload],
  (bundles: BundleState, { bundleContentId, contentId }) => {
    const bundleContent = bundles?.byContentId?.[bundleContentId]
    let content: ContentEntity
    const bundleModule = (bundleContent?.bundle?.bundleModules || []).find((module) => {
      const moduleContent = (module?.moduleContents || []).find(
        (moduleContent) => moduleContent.contentId === contentId,
      )
      if (moduleContent) {
        content = moduleContent.content
        return true
      }
      return false
    })

    return {
      currentModule: bundleModule,
      currentContent: content,
    }
  },
)

/**
 * Select all bundle content and transform
 * @param bundleContentId
 * @param theme
 * @param channelPathname
 * @param bundleId
 * @param contentId
 * @returns
 * modulesData: ModuleProgressInterface[]
 * defaultModuleOpened: string
 */
export const selectAllBundleContentAndTransform = createSelector(
  [(state: RootState) => state, (state, payload) => payload],
  (state: RootState, { bundleContentId, theme, channelPathname, bundleId, contentId }) => {
    // Get the bundle modules
    const { bundles } = state
    const bundleContent = bundles?.byContentId?.[bundleContentId]
    const bundleModules = bundleContent?.bundle?.bundleModules || []
    // Set up default module opened
    let defaultModuleOpened = ''
    // Loop through each bundle modules and transform the data
    const modulesData: ModuleProgressInterface[] = bundleModules.map((module, index) => {
      // Set up module contents
      let moduleContents = []
      // Set up content progress
      let contentProgress: number[] = []
      if (!isEmptyArray(module.moduleContents)) {
        // Loop through each module contents and transform the data
        moduleContents = module.moduleContents.map((moduleContent) => {
          // Set up content duration and content length
          let duration = ''
          let contentLength = 0
          switch (moduleContent.content.contentType) {
            case 'vod':
              duration = formatDigitalDuration(moduleContent.content.vod.length)
              contentLength = moduleContent.content.vod.length ?? 0
              break
            case 'audio':
              duration = formatDigitalDuration(moduleContent.content.audio.length)
              contentLength = moduleContent.content.audio.length ?? 0
              break
          }

          // Set up content link
          let contentLink = ''
          const baseRoutePath = `/${channelPathname}/${moduleContent.content.contentId}`
          const baseParams = {
            bundle_id: bundleId,
            bundle_content_id: bundleContentId,
            bundle_module_id: moduleContent.bundleModuleId,
            ...DEFAULT_AUTOPLAY_QUERY_OBJECT,
          }
          contentLink = buildPathWithParams(`${baseRoutePath}/watch`, baseParams)

          // Set up current playing id
          let currentPlaying = false
          if (contentId === moduleContent.contentId) {
            currentPlaying = true
            defaultModuleOpened = module.bundleModuleId
          }

          // Calculate content progress
          const playbackData = selectContentResumePlaybackById(moduleContent.contentId)(state)
          let progress = 0
          if (playbackData) {
            progress = Math.round(getPercentage(playbackData?.lastKnownTime, contentLength))
            if (progress > 100) progress = 100
          }
          contentProgress.push(progress)

          const mContent: ModuleContentItemsInterface = {
            id: moduleContent.contentId,
            contentTitle: moduleContent.content.title,
            order: moduleContent.order,
            contentType: moduleContent.content.contentType,
            duration,
            progress,
            contentLink,
            currentPlaying,
          }
          return mContent
        })
      }

      // Calculate module progress
      let progress = 0
      if (!isEmptyArray(contentProgress) && !isEmptyArray(moduleContents)) {
        const sumContentProgress = contentProgress.reduce((acc, curr) => acc + curr, 0)
        const totalProgress = moduleContents.length * ROUNDER
        progress = Math.round(getPercentage(sumContentProgress, totalProgress))
      }

      // Sort contents by order
      const _moduleContents = moduleContents.sort(compareArrayOfObjects('order', 'asc'))

      const moduleData: ModuleProgressInterface = {
        id: module.bundleModuleId,
        name: '', // placeholder only
        title: module.title,
        order: module.order,
        theme,
        progress,
        moduleContents: _moduleContents,
      }
      return moduleData
    })

    // Sort modules by order
    // Assign correct name to each module (eg: Module 1, Module 2)
    const _modulesData = modulesData
      .sort(compareArrayOfObjects('order', 'asc'))
      .map((module, index) => {
        return {
          ...module,
          name: `Module ${index + 1}`,
        }
      })

    return {
      modulesData: _modulesData,
      defaultModuleOpened,
    }
  },
)

/**
 * Select bundle content ids by content id
 * @param contentId
 * @returns string[]
 */
export const selectBundleContentIdsByContentId = createSelector(
  [({ bundles }: RootState) => bundles, (state, contentId: string) => contentId],
  (bundles, contentId): string[] => {
    const contentIds: string[] = []
    const modules = bundles?.byContentId?.[contentId]?.bundle?.bundleModules
    if (!isEmptyArray(modules)) {
      modules.map((modules) => {
        if (!isEmptyArray(modules?.moduleContents)) {
          return modules?.moduleContents.map((moduleContent) => {
            contentIds.push(moduleContent.contentId)
          })
        }
      })
    }

    return contentIds
  },
)

/**
 * Select bundle module dropdown options
 * @param bundleId
 */
export const selectBundleModules = createSelector(
  [({ contents }: RootState) => contents, (state, bundleId: string) => bundleId],
  (contents, bundleId) => {
    const bundleModules = contents?.byContentId?.[bundleId]?.bundle?.bundleModules || []
    return [...bundleModules].sort(compareArrayOfObjects('order', 'asc'))
  },
)

/**
 * Get first bundle module
 * @param bundleId
 */
export const selectFirstBundleModuleContent = createSelector(
  [(state: RootState) => state, (state, bundleId: string) => bundleId],
  (state, bundleId) => {
    const modules = selectBundleModules(state, bundleId)
    if (!isEmptyArray(modules) && !isEmptyArray(modules[0].moduleContents)) {
      return modules[0].moduleContents[0]
    }
    return null
  },
)

/**
 * Get Module data by content id and module id
 */
export const selectContentsByBundleAndModule = (bundleId: string, moduleId: string) =>
  createSelector(
    (state: RootState) => state.bundles,
    (bundles: BundleState): ContentEntity[] | undefined => {
      const modules = bundles?.bundleModules?.[bundleId] || []
      if (!isEmptyArray(modules)) {
        return modules.find((module) => module.moduleId === moduleId)?.contents || []
      }
      return []
    },
  )

/**
 * Get Module data by content id and module id
 */
export const selectModuleDurationByContentIdModuleId = (contentId: string, moduleId: string) =>
  createSelector(
    (state: RootState) => state.contents,
    (contents: ContentsState): number => {
      const modules = contents?.byContentId?.[contentId]?.bundle?.bundleModules || []
      if (!isEmptyArray(modules)) {
        const moduleData =
          modules.find((module) => module.bundleModuleId === moduleId)?.moduleContents || []
        if (moduleData) {
          const times = moduleData.map((module) => {
            const content = contents?.byContentId?.[module.contentId]
            if (content) {
              if (content?.contentType === 'vod' && hasProp(content?.vod?.length)) {
                return content?.vod?.length
              } else if (content?.contentType === 'audio' && hasProp(content?.audio?.length)) {
                return content?.audio?.length
              } else {
                return 0
              }
            }
          })

          return !isEmptyArray(times)
            ? times.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
            : 0
        }
      }
      return 0
    },
  )

/**
 * Get first content in module
 */
export const selectFirstContentInModule = (bundleId: string, moduleId: string) =>
  createSelector(
    (state: RootState) => state.bundles,
    (bundles: BundleState): ContentEntity | undefined => {
      const modules = bundles?.bundleModules?.[bundleId]
      if (!modules) return undefined

      const module = modules.find((module) => module.moduleId === moduleId)
      if (!module || isEmptyArray(module.contents)) return undefined

      return module.contents[0]
    },
  )
