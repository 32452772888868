import { DEFAULT_AUTOPLAY_QUERY } from '@shared/constants/root.constants'

export const generateContentPath = (
  contentId: string,
  channelPathname: string,
  isPurchased = false,
) => `/${channelPathname}/${contentId}${isPurchased ? `?${DEFAULT_AUTOPLAY_QUERY}` : ''}`

interface GenerateBoughtBundlePathInterface {
  playingId: string
  channelPathname: string
  contentId: string
  moduleId: string
}

export const generateBoughtBundlePath = ({
  playingId,
  channelPathname,
  contentId,
  moduleId,
}: GenerateBoughtBundlePathInterface) =>
  `/${channelPathname}/${playingId}/${contentId}?module=${moduleId}&${DEFAULT_AUTOPLAY_QUERY}`
