import { useAppSelector as useSel } from '@root/store'
import {
  selectBundleFirstModuleDataByContentId,
  selectBundlePropByContentId,
  selectContentById,
} from '@store/contents-slice/_selectors'
import { useEffect, useMemo, useRef } from 'react'
import { isDefined } from '@utils/connascence'
import { generateBoughtBundlePath, generateContentPath } from '@utils/content/generate-content-path'
import useUserHasAccessToContent from '@hooks/_content/use-user-has-access-to-content'
import useFetchModuleContents from '@hooks/_content/bundle/use-fetch-module-contents'
import { selectFirstContentInModule } from '@store/bundle-slice/_selectors'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'

export default function useBoughtPath(contentId: string, channelUrl: string) {
  const channelId = useSel(selectChannelPropByChannelPathname(channelUrl)('channelId'))
  const content = useSel(selectContentById(contentId))
  const firstModule = useSel(selectBundleFirstModuleDataByContentId(contentId))
  const userHasAccess = useUserHasAccessToContent(contentId)
  const bundleId = useSel(selectBundlePropByContentId(contentId)('bundleId'))
  const firstContentInBundle = useSel(
    selectFirstContentInModule(bundleId, firstModule?.bundleModuleId),
  )
  const fetchedModule = useRef(false)
  const { fetchModuleData } = useFetchModuleContents(bundleId, channelId)

  // Fetch First module data to build path to play bundle
  useEffect(() => {
    if (
      !fetchedModule.current &&
      content?.contentType === 'bundle' &&
      userHasAccess &&
      isDefined(firstModule)
    ) {
      fetchModuleData(firstModule.bundleModuleId)
      fetchedModule.current = true
    }
  }, [contentId, firstModule, content, userHasAccess, fetchModuleData])

  return useMemo(() => {
    if (isDefined(firstContentInBundle)) {
      const { contentId: playingId } = firstContentInBundle
      const moduleId = firstModule?.bundleModuleId

      return generateBoughtBundlePath({
        playingId,
        contentId,
        channelPathname: channelUrl,
        moduleId,
      })
    } else {
      return generateContentPath(channelUrl, contentId)
    }
  }, [firstContentInBundle, bundleId, firstModule?.bundleModuleId, channelUrl, contentId])
}
