import { getModuleContentByModuleIdThunk } from '@store/bundle-slice/_thunks'
import { useDispatch } from 'react-redux'

export default function useFetchModuleContents(bundleId: string, channelId: string) {
  const dispatch = useDispatch()

  const fetchModuleData = (moduleId: string) => {
    dispatch(getModuleContentByModuleIdThunk({ moduleId, bundleId, channelId }))
  }

  return { fetchModuleData }
}
